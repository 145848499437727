import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Box, Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modalTitle: {
    padding: "1.5rem 2rem 0rem",
    color: "#005CB2",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    }
  },
  modalQuestion:{
    color:'#212121',
    fontSize:'18px',
    fontWeight:'500',
    marginBottom:"10px",
    marginTop:'2rem',
    textAlign:"justify",
    lineHeight:"23px",
    '@media screen and (min-width: 768px)' : {
      fontSize:'20px',
      fontWeight: '500',
      lineHeight: '26px',
    },
    '@media screen and (min-width: 1024px)' : {
      fontSize:'22px',
    }
  },
  modalYourAnswer:{
    color:"#12ED1B",
    fontSize:'16px',
    marginLeft:'1rem',
    paddingBottom:"10px",
    '@media screen and (min-width: 768px)' : {
      fontSize:'18px',
    },
    '@media screen and (min-width: 1024px)' : {
      fontSize:'20px',
    }
  },
  modalCorrecAnswer:{
    color:'#ED3535',
    fontSize:'16px',
    marginLeft:'1rem',
    '@media screen and (min-width: 768px)' : {
      fontSize:'18px',
    },
    '@media screen and (min-width: 1024px)' : {
      fontSize:'20px',
    }
  },
  modalButton: {
    display:'flex',
    margin:'auto',
    marginTop:'2rem',
    height:'35px',
    width:'100px',
    fontSize:'16px',
    textTransform: 'none',
    '@media screen and (min-width: 768px)' : {
      height:'45px',
      width:'150px',
      borderRadius:'10px',
      fontSize:'18px',
    },
    '@media screen and (min-width: 1024px)' : {
      height:'50px',
      width:'180px',
      fontSize:'22px',
      marginBottom:'0'
    }
  },
}));


const Modal = ({ onClose, results, data }) => {
  const classes = useStyles();
  return(
    <Container>
      <Typography variant="h4" component="h1" align="center" className={classes.modalTitle}>Đáp án</Typography>
      <Grid>
            {results.map((result, i) => (
              <Box key={i}>
                <Typography className={classes.modalQuestion}>{result.q}</Typography>
                <Typography className={classes.modalYourAnswer}>Đáp án đã chọn: {result.a}</Typography>
                {result.a !== data[i].answer && <Typography className={classes.modalCorrecAnswer}>Đáp án đúng: {data[i].answer}</Typography>}
              </Box>
            ))}
      </Grid>
      <Button variant="contained" color="primary"  className={classes.modalButton} onClick={onClose}>Đóng</Button>
    </Container>
  );
}

export default Modal;