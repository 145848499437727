import React from "react"
import { graphql } from "gatsby"

import SEO from "../../components/shell/seo"
import Layout from "../../components/shell/layout"
import LessonDetail from "../../components/learning/pageContents/LessonDetail"
import Quiz from "../../components/learning/pageContents/Quiz"
import { LESSON_TYPE } from "../../constants/LessonType"

import CustomBreadcrumb from "../../components/common/CustomBreadcrumb"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"

function LessonDetailWrapper({ data, pageContext, location, params }) {
  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: data.lessonsJson.name,
  })

  const lessonsData = data.lessonsJson
  if (lessonsData.type !== LESSON_TYPE.QUIZ) {
    lessonsData.contents.map(content => {
      if (content.codeExampleID) {
        const codeExampleData = pageContext.codeExamples.find(
          item => item.Id === content.codeExampleID
        )

        content.displayCode = codeExampleData.displayCode
        content.codeSnippet = codeExampleData.codeSnippet
      }
    })
  }

  return (
    <Layout>
      <SEO title={lessonsData.name} />
      {/* <CustomBreadcrumb crumbs={crumbs} /> */}
      {lessonsData.type === LESSON_TYPE.QUIZ ? (
        <Quiz data={lessonsData} />
      ) : (
        <LessonDetail
          data={lessonsData}
          prevPage={pageContext.prevPage}
          nextPage={pageContext.nextPage}
        />
      )}
    </Layout>
  )
}

export default LessonDetailWrapper

export const query = graphql`
  query($id: String) {
    lessonsJson(id: { eq: $id }) {
      id
      courseId
      name
      title
      contents {
        Text
        codeExampleID
        subDescription
      }
      data {
        answer
        choices {
          choice
        }
        question
      }
      type
      subtitle1 {
        description
      }
      subtitle2 {
        description
      }
    }
  }
`
