import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Highlight from "react-highlight";
import ALink from "../../../common/Alink";
import MarkdownViewer from "../../../common/MarkdownViewer";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "0.5rem",
    "@media screen and (min-width: 1024px)": {
      paddingTop: "1.5rem",
    },
  },
  codeExample: {
    margin: "1rem 2%",
    padding: theme.spacing(2),
    lineHeight: 1.7,
    "@media screen and (min-width: 768px)": {
      margin: "1rem",
    },
  },
  codeExampleLink: {
    textDecoration: "none",
    marginLeft: "2%",
    "@media screen and (min-width: 768px)": {
      marginLeft: "1rem",
    },
  },
  exampleDetail: {
    marginBottom: "1rem",
  },
}));

export default function LessonItem({ data }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* content */}
      <MarkdownViewer data={data.Text} />

      {data.displayCode && (
        <Highlight language="html" className={classes.codeExample}>
          {data.displayCode.code}
        </Highlight>
      )}

      {/* link to code editor */}
      {data.codeSnippet && (
        // <ALink
        //   // to={`/code-editor/${data.codeExampleID}`}
        //   // target="_blank"
        //   className={classes.codeExampleLink}
        // >
        <Button
          className={classes.exampleDetail}
          variant="contained"
          color="primary"
          onClick={() => { window && window.open(`/code-editor/${data.codeExampleID}`, '_blank') }}
        >
          Xem ví dụ
        </Button>
        // </ALink>
      )}

      {/* sub content */}
      {data.subDescription ? (
        <MarkdownViewer data={data.subDescription} />
      ) : null}
    </div>
  );
}
