import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Alink from "../../../common/Alink";
import { Container, Grid } from '@material-ui/core';
import { isLoggedIn } from '../../../../utils/auth';
import { addTestingRecord, formatTime, getCorrectAnswers } from "../../../../api/testingHistory.service";
import { addOrUpdateLearningProgressItem } from '../../../../api/learningProgress.service';
import getFirebase from '../../../../utils/firebase';

const useStyles = makeStyles((theme) => ({
  endTitle: {
    padding: "1.5rem 2rem 1.5rem",
    color: "#005CB2",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    }
  },
  endResult1: {
    textAlign: 'center',
    color: '#ED3535',
    fontSize: '25px',
    fontWeight: 700,
    marginBottom: '1rem',
    '@media screen and (min-width: 768px)': {
      fontSize: '30px',
    },
  },
  endResult2: {
    textAlign: 'center',
    color: '#12ED1B',
    fontSize: '25px',
    fontWeight: 700,
    marginBottom: '1rem',
    '@media screen and (min-width: 768px)': {
      fontSize: '30px',
    },
  },
  endResult3: {
    textAlign: 'center',
    fontSize: '22px',
    marginBottom: '1rem',
    '@media screen and (min-width: 768px)': {
      fontSize: '25px',
    },
    '@media screen and (min-width: 1024px)': {
      fontSize: '30px',
    },
  },
  endButton: {
    display: 'flex',
    margin: 'auto',
    textTransform: 'none',
    height: '35px',
    width: '150px',
    fontSize: '16px',
    '@media screen and (min-width: 768px)': {
      height: '45px',
      width: '180px',
      borderRadius: '10px',
      fontSize: '18px',
    },
    '@media screen and (min-width: 1024px)': {
      height: '50px',
      width: '220px',
      borderRadius: '10px',
      fontSize: '25px',
      marginBottom: '0'
    }
  },
  endLink: {
    textDecoration: 'none'
  }
}));

const End = ({ results, data, onReset, onAnswersCheck, time }) => {

  useEffect(() => {
    if (isLoggedIn(getFirebase())) {
      addTestingRecord(results, data, time);
      addOrUpdateLearningProgressItem(data.id, data.courseId, data.type, getCorrectAnswers(results, data), data.data.length);
    }
  }, [isLoggedIn(getFirebase())]);

  const classes = useStyles();
  return (
    <Container>
      <Typography variant="h4" component="h1" align="center" className={classes.endTitle}>Kết Quả</Typography>
      <Typography align="center" className={classes.endResult1}> {getCorrectAnswers(results, data)}/{data.data.length}</Typography>
      <Typography align="center" className={classes.endResult2}>{Math.floor((getCorrectAnswers(results, data) / data.data.length) * 100)}%</Typography>
      <Typography align="center" className={classes.endResult3}>Thời gian thực hiện: <strong>{formatTime(time)}</strong></Typography>
      <Grid container spacing={3} justify="center" alignItems="center">
        <Grid item >
          <Button variant="contained" color="primary" className={classes.endButton} onClick={onAnswersCheck}>Kiểm tra đáp án</Button>
        </Grid>
        <Grid item >
          <Button variant="contained" color="primary" className={classes.endButton} onClick={onReset}>Làm lại</Button>
        </Grid>
        <Grid item >
          <Alink to='/courses' className={classes.endLink}>
            <Button variant="contained" color="primary" className={classes.endButton} >Khóa học khác</Button>
          </Alink>
        </Grid>
      </Grid>

    </Container>
  );
}

export default End;