import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  questionTitle:{
    padding: "1.5rem 2rem 1.5rem",
    color: "#005CB2",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    }
  },
  quizQuestion:{

    color:'#212121',
    fontSize:'18px',
    fontWeight:'500',
    marginBottom:"10px",
    textAlign:"justify",
    lineHeight:"23px",
    '@media screen and (min-width: 768px)' : {
      fontSize:'22px',
      fontWeight: '500',
      lineHeight: '26px',
    },
  },
  quizAnswer:{
    marginLeft:'0px',
    '@media screen and (min-width: 768px)' : {
    
    },
    '@media screen and (min-width: 1024px)' : {
    
    }
  },
  nextButton: {
    display:'flex',
    marginLeft:'0',
    textTransform: 'none',
    height:'35px',
    width:'100px',
    fontSize:'16px',
    marginTop: '1rem',
    '@media screen and (min-width: 768px)' : {
      height:'45px',
      width:'150px',
      borderRadius:'10px',
      fontSize:'18px',
    },
    '@media screen and (min-width: 1024px)' : {
      height:'50px',
      width:'180px',
      fontSize:'25px',
    }
  },
  questionError:{
    color:'#ED3535',
  }
}));


const Question = ({ data,data1, onAnswerUpdate, numberOfQuestions, activeQuestion, onSetActiveQuestion, onSetStep }) => {
  const [selected, setSelected] = useState('');
  const [error, setError] = useState('');
  const radiosWrapper = useRef();
  const [value, setValue] = useState('');

  useEffect(() => {
    const findCheckedInput = radiosWrapper.current.querySelector('input:checked');
    if(findCheckedInput) {
      findCheckedInput.checked = false;
    }
  }, [data]);

  const changeHandler = (e) => {
    setValue(e.target.value);
    setSelected(e.target.value);
    if(error) {
      setError('');
    }
  }
  
  const nextClickHandler = (e) => {
    setValue('');
    if(selected === '') {
      return setError('Hãy chọn đáp án!');
    }
    onAnswerUpdate(prevState => [...prevState, { q: data.question, a: selected }]);
    setSelected('');
    if(activeQuestion < numberOfQuestions - 1) {
      onSetActiveQuestion(activeQuestion + 1);
    }else {
      onSetStep(3);
    }
  }
  const classes = useStyles();
  return(
    
        <Container>
          <Typography variant="h4" component="h1" align="center" className={classes.questionTitle}>{data1.name}</Typography>
            <Container>
              
              <FormControl component="fieldset" ref={radiosWrapper}>
              <Typography className={classes.quizQuestion}>{data.question}</Typography>
                  <RadioGroup  aria-label="quiz" name="customized-radios" className={classes.quizAnswer} value={value} onChange={changeHandler}>
                    {data.choices.map((choices)=>{
                      return(
                        <FormControlLabel value={choices.choice}  control={<Radio />} label={choices.choice} />
                          )
                        })
                    }
                    {error && <Typography className={classes.questionError}><strong>{error}</strong></Typography>}
                  </RadioGroup>
              </FormControl>
               <Button variant="contained" color="primary"  className={classes.nextButton} onClick={nextClickHandler}>
            Next
          </Button>
            </Container>
        </Container>
  );
}

export default Question;