import React from "react";
import Typography from "@material-ui/core/Typography";
import ContentItem from "../sections/lessonDetail/ContentItem";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "../../common/PageStep";
import Paper from "@material-ui/core/Paper";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "calc( 100vh - 56px)",
    paddingBottom: "477px",
    transition: "all .3s ease-in-out",

    [theme.breakpoints.up("sm")]: {
      paddingBottom: "230px",
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "233px",
      paddingTop: "40px",
      width: "85%",
      margin: "0 auto",
    },
    "@media screen and (max-width: 356px)": {
      paddingBottom: "496px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: "0",
      paddingLeft: "0",
    },
  },
  paper: {
    padding: "5%",
    backgroundColor: theme.palette.background.paper,
    marginBottom: "0.5rem",
    borderRadius: 0,
  },
  elevation: {
    [theme.breakpoints.down("xm")]: {
      boxShadow: "none",
    },
  },
  lessonTitle: {
    paddingTop: "20px",
    color: "#005CB2",
    fontSize: "20px",
    "@media screen and (min-width: 768px)": {
      fontSize: "25px",
      paddingTop: "0",
    },
    "@media screen and (min-width: 1024px)": {
      fontSize: "30px",
    },
  },
}));

export default function LessonDetail({ data, prevPage, nextPage }) {
  const classes = useStyles();
  return data ? (
    <React.Fragment>
      <Container className={classes.container}>
        <Paper
          elevation={3}
          className={classes.paper}
          classes={{ root: classes.elevation }}
        >
          <Typography
            className={classes.lessonTitle}
            variant="h4"
            component="h1"
            align="center"
          >
            {data.name}
          </Typography>
          <div>
            {data.contents.map((item, index) => {
              return <ContentItem key={index} data={item} />;
            })}
          </div>
        </Paper>
        <Pagination data={data} prevPage={prevPage} nextPage={nextPage} />
      </Container>
    </React.Fragment>
  ) : null;
}
