import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  startTitle:{
    padding: "1.5rem 2rem 1.5rem",
    color: "#005CB2",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    }
  },
  startSubTitle1:{
    fontSize:'20px',
    marginBottom:'20px',
    fontWeight:'500',
    '@media screen and (min-width: 1024px)' : {
      marginLeft:'15rem'
    }
  },
  startSubTitle2:{
    color:'#005CB2',
    fontSize:'18px',
    marginBottom:'20px',
    fontWeight:'560',
    '@media screen and (min-width: 768px)' : {
      fontSize:'22px',
    },
    '@media screen and (min-width: 1024px)' : {
      fontWeight:'500'
    }
  },
  startButton: {
    display: 'flex',
    margin:"auto",
    height:'35px',
    width:'100px',
    fontSize:'16px',
    '@media screen and (min-width: 768px)' : {
      height:'45px',
      width:'150px',
      borderRadius:'10px',
      fontSize:'18px',
    },
    '@media screen and (min-width: 1024px)' : {
      height:'50px',
      width:'180px',
      fontSize:'25px',
    }
},

}));

const Start = ({ data, onQuizStart }) => {
  const classes = useStyles();
  return(
        <Container>
          <Typography  variant="h4" component="h1" align="center" className={classes.startTitle}>{data.name}</Typography>
          {data.subtitle1.map((item)=>{
            return(
              <Typography  align="justify" className={classes.startSubTitle1}>{item.description}</Typography>
            )
          })}
          {data.subtitle2.map((item)=>{
            return(
              <Typography  align="center" className={classes.startSubTitle2}>{item.description}</Typography>
            )
          })}
          <Button variant="contained" color="primary" className={classes.startButton} onClick={onQuizStart}>
            START
          </Button>
        </Container>
  );
}

export default Start;