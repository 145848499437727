import React, { useState, useEffect } from "react";
import Start from "../sections/quiz/Start";
import Question from "../sections/quiz/Question";
import End from "../sections/quiz/End";
import Modal from "../sections/quiz/Modal";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: "50px",
    paddingBottom: "650px",
    transition: "all .3s ease-in-out",
    [theme.breakpoints.up("sm")]: {
      paddingBottom: "370px",
    },
  },
  root: {
    margin: "0 auto",
    wordBreak: "break-word",
    paddingBottom: "2rem",
  },
}));

let interval;
const Quiz = ({ data }) => {
  const [step, setStep] = useState(1);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [time, setTime] = useState(0);

  useEffect(() => {
    if (step === 3) {
      clearInterval(interval);
    }
  }, [step]);

  const quizStartHandler = () => {
    setStep(2);
    interval = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const resetClickHandler = () => {
    setShowModal(false);
    setActiveQuestion(0);
    setAnswers([]);
    setStep(2);
    setTime(0);
    interval = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
  };
  const classes = useStyles();
  return (
    <React.Fragment>
      <Container className={classes.container}>
        <Paper elevation={3} className={classes.root}>
          {step === 1 && <Start data={data} onQuizStart={quizStartHandler} />}
          {step === 2 && (
            <Question
              data={data.data[activeQuestion]}
              data1={data}
              onAnswerUpdate={setAnswers}
              numberOfQuestions={data.data.length}
              activeQuestion={activeQuestion}
              onSetActiveQuestion={setActiveQuestion}
              onSetStep={setStep}
            />
          )}
          {step === 3 && (
            <End
              results={answers}
              data={data}
              onReset={resetClickHandler}
              onAnswersCheck={() => setShowModal(true)}
              time={time}
            />
          )}

          {showModal && (
            <Modal
              onClose={() => setShowModal(false)}
              results={answers}
              data={data.data}
            />
          )}
        </Paper>
      </Container>
    </React.Fragment>
  );
};

export default Quiz;
