import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ALink from "./Alink";

import IconButton from "@material-ui/core/IconButton";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import { isLoggedIn } from "../../utils/auth";
import { addOrUpdateLearningProgressItem } from "../../api/learningProgress.service";
import { Container } from "@material-ui/core";
import getFirebase from "../../utils/firebase";

const useStyles = makeStyles((theme) => ({
  pageSteper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "@media screen and (min-width: 768px)": {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  pageStepLink: {
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pageStepButton: {
    borderRadius: "5px",
    backgroundColor: "#1E88E5",
    boxShadow: "2px 2px 10px #888888",
    color: "#FFFFFF",
    fontSize: "14px",
    whiteSpace: "nowrap",
    "@media screen and (min-width: 768px)": {
      fontSize: "16px",
      transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
      "&:hover": {
        backgroundColor: "#005DB2",
      },
    },
  },
  linkNext: {
    padding: theme.spacing(0.5, 0, 0.5, 1.2),
    "@media screen and (min-width: 768px)": {
      padding: theme.spacing(0.8, 0, 0.8, 1.8),
    },
  },
  linkPre: {
    padding: theme.spacing(0.5, 1.2, 0.5, 0),
    "@media screen and (min-width: 768px)": {
      padding: theme.spacing(0.8, 1.8, 0.8, 0),
    },
  },
  quizButton: {
    width: theme.spacing(12),
  },
}));

function Pagination(props) {
  const classes = useStyles();
  const courseId = props.data.courseId;
  const nextPage = props.nextPage;
  const prevPage = props.prevPage;

  const handleClick = () => {
    if (isLoggedIn(getFirebase())) {
      addOrUpdateLearningProgressItem(props.data.id, courseId);
    }
  };

  const Next = () => {
    const classes = useStyles();
    const NextButton = () => {
      return (
        <ALink
          className={classes.pageStepLink}
          to={`/courses/${courseId}/lessons/${courseId}_${nextPage}`}
        >
          <IconButton
            variant="contained"
            color="primary"
            onClick={handleClick}
            className={`${classes.pageStepButton} ${classes.linkNext}`}
          >
            <span>Bài sau</span>
            <ArrowRightIcon />
          </IconButton>
        </ALink>
      );
    };
    return nextPage === "" ? (
      <ALink
        className={classes.pageStepLink}
        to={`/courses/${courseId}/lessons/${courseId}_quiz`}
      >
        <IconButton
          variant="contained"
          color="primary"
          onClick={handleClick}
          className={`${classes.pageStepButton} ${classes.linkNext} ${classes.quizButton}`}
        >
          <span>Quiz</span>
          <ArrowRightIcon />
        </IconButton>
      </ALink>
    ) : (
      <NextButton />
    );
  };

  const Prev = () => {
    const classes = useStyles();
    const PrevButton = () => {
      return (
        <ALink
          className={classes.pageStepLink}
          to={`/courses/${courseId}/lessons/${courseId}_${prevPage}`}
        >
          <IconButton
            variant="contained"
            color="primary"
            onClick={handleClick}
            className={`${classes.pageStepButton} ${classes.linkPre}`}
          >
            <ArrowLeftIcon />
            <span>Bài trước</span>
          </IconButton>
        </ALink>
      );
    };
    return prevPage > 0 ? <PrevButton /> : " ";
  };

  return (
    <section className={classes.pageSteper}>
      <Container>
        <Grid container>
          <Grid container item xs={6} justify="flex-start">
            <Prev />
          </Grid>
          <Grid container item xs={6} justify="flex-end">
            <Next />
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default Pagination;
